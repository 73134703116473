figure.logo {
    margin: 16px 0;
    display: flex;
}

figure.logo > svg {
    cursor: pointer;
    min-width: 26px;
    margin: 8px 0;
}

.logomask {
    color: #FFFFFF;
    background-color: #282c34;
}
