
.editor-wrapper {
    /*border: 1px solid black;*/
    cursor: text;
    min-height: 100%;
    margin: 24px 48px;
    font-size: 24px;
    line-height: 1.5em;
}

.full-page-editor {
    width: 100vw;
    min-height: 100vh;
    font-size: calc(18px + 2vmin);
    color: #282c34;
}

.public-DraftEditorPlaceholder-root,
.public-DraftEditor-content {
    /*margin: 0 -15px -15px;*/
    /*padding: 15px;*/
}

.DraftEditor-root .public-DraftEditorPlaceholder-root {
    color: #555;
}